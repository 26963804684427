import * as React from 'react';
import { useState } from 'react';
import { AnimatedLink, AppText } from './';
import { mobileSize } from '../../util/variables';
import theme from '../../util/theme';
import styled, { keyframes } from 'styled-components';

export const AcceptCookies = () => {
  const [acceptCookies, setAcceptCookies] = useState(false);
  return (
    <AcceptCookiesContainer acceptCookies={acceptCookies}>
      <AcceptCookiesWrapper>
        <CookiesText>
          Base10 uses cookies to bring you the best experience possible. In order to use this
          website, you must agree our{' '}
          <AnimatedLink to="/terms-of-use/" fontSize="18px" underlineHeight="2px">
            Terms of Use
          </AnimatedLink>
          .
        </CookiesText>
        <AcceptButton onClick={() => setAcceptCookies(true)}>Agree</AcceptButton>
      </AcceptCookiesWrapper>
    </AcceptCookiesContainer>
  );
};

const CookiesText = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    a {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

const AcceptButton = styled.button`
  font-family: ${theme.PRIMARY_FONT};
  justify-self: end;
  align-self: center;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  background: ${theme.PRIMARY_COLOR};
  color: ${theme.WHITE_COLOR};
  vertical-align: middle;
  text-align: center;
  width: 160px;
  height: 56px;
  transition: opacity 250ms ease;
  :hover {
    opacity: 0.9;
  }
  @media ${mobileSize} {
    font-size: 14px;
    margin-top: 15px;
    width: 40vw;
  }
`;

const AcceptCookiesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const slideDown = keyframes`
    10% {transform: translateY(-2px) }
    100% {transform: translateY(-400px) };  
`;

const AcceptCookiesContainer = styled.div<{ acceptCookies: boolean }>`
  display: ${props => (props.acceptCookies ? 'none' : 'block')};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px 54px;
  z-index: 7;
  background-color: ${theme.WHITE_COLOR};
  box-shadow: rgba(26, 26, 26, 0.1) 0px -10px 40px 0px;
  transition: ${slideDown} 2000ms ease-in;
  @media ${mobileSize} {
    padding: 15px 25px 20px 25px;
  }
`;
