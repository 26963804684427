import styled from "styled-components";
import { mobileSize } from "../../util/variables";

type Direction = "row" | "column" | "row-reverse" | "column-reverse";
type Align = "normal" | "stretch" | "center" | "flex-start" | "flex-end" | "baseline";
type Justify =
  | "left"
  | "right"
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-evenly"
  | "space-around"
  | "space-between";
type Wrap = "nowrap" | "wrap" | "wrap-reverse";

interface FlexProps {
  direction?: Direction;
  align?: Align;
  justify?: Justify;
  gap?: number;
  wrap?: Wrap;
  mobileDirection?: Direction;
  mobileAlign?: Align;
  mobileJustify?: Justify;
  mobileGap?: number;
  mobileWrap?: Wrap;
}

export const FlexDiv = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "normal")};
  justify-content: ${props => (props.justify ? props.justify : "flex-start")};
  gap: ${props => (props.gap ? props.gap : 0)}px;
  flex-wrap: ${props => props.wrap ?? "nowrap"};
  @media ${mobileSize} {
    flex-direction: ${({ mobileDirection, direction }) =>
      mobileDirection ? mobileDirection : direction ? direction : "row"};
    align-items: ${({ mobileAlign, align }) =>
      mobileAlign ? mobileAlign : align ? align : "normal"};
    justify-content: ${({ mobileJustify, justify }) =>
      mobileJustify ? mobileJustify : justify ? justify : "flex-start"};
    gap: ${({ mobileGap, gap }) => (mobileGap !== undefined ? mobileGap : gap ? gap : 0)}px;
    flex-wrap: ${({ mobileWrap, wrap }) => mobileWrap ?? wrap ?? "nowrap"};
  }
`;
