import React from "react";
import { GooglePlayBadge } from "../../content/assets";
import styled from "styled-components";
import { mobileSize } from "../../util/variables";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const DownloadAndroid = () => {
  return (
    <OutboundLink
      href="https://play.google.com/store/apps/details?id=com.polywise.lucid&hl=en_US&gl=US"
      target="_blank"
      rel="noopener noreferrer"
    >
      <GoogleBadge src={GooglePlayBadge} alt="Get it on Google Play" />
    </OutboundLink>
  );
};

const GoogleBadge = styled.img.attrs({
  width: 181,
  height: 56,
})`
  @media ${mobileSize} {
    width: 167px;
    height: 50px;
  }
`;
