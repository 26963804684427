import React from "react";
import styled from "styled-components";

interface ModalProps {
  children: React.ReactNode;
  close: () => void;
}

// NOTE: ModalContext should be created.
// NOTE: Portals cannot be used with Gatsby
const Modal: React.FC<ModalProps> = ({ children, close }) => {
  return (
    <ModalOverlay onClick={close}>
      <ModalContent onClick={e => e.stopPropagation()}>{children}</ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - 48px); // 48px -> padding of overlay
  max-width: 996px;
  overflow: hidden;
`;

export default Modal;
