import * as React from "react";
import styled from "styled-components";
import theme from "../../util/theme";

interface BurgerProps {
  showNav: () => void;
  showNavState: boolean;
  invert?: boolean;
}

interface BurgerState {
  showNav: boolean;
  visible: boolean;
}

// declare global {
//   interface Window {
//     pageYoffset: any;
//   }
// }

class BurgerIcon extends React.Component<BurgerProps, BurgerState> {
  constructor(props: BurgerProps) {
    super(props);

    this.state = {
      showNav: false,
      visible: true,
    };
  }

  componentDidMount() {
    typeof window !== "undefined" && window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    typeof window !== "undefined" && window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <BurgerContainer visible={this.state.visible} showNav={this.state.showNav}>
        <BurgerIconDiv showNav={this.props.showNavState} onClick={this.props.showNav}>
          <Bar invert={this.props.invert} />
          <Bar invert={this.props.invert} />
          <Bar invert={this.props.invert} />
        </BurgerIconDiv>
      </BurgerContainer>
    );
  }
}

interface ContainerProps {
  visible: boolean;
  showNav: boolean;
}

interface IconState {
  showNav: boolean;
}
const BurgerContainer = styled.div<ContainerProps>`
`;

interface InvertProps {
  invert?: boolean;
}

const Bar = styled.span<InvertProps>`
  display: block;
  width: 22px;
  height: 2px;
  margin: 5px;
  border-right: 22px solid ${theme.PRIMARY_COLOR};
  border-radius: 3px;
  /* 	border-left: 0.6em solid #d6d6d6; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-transition-property: -webkit-transform, margin, border-right-color, box-shadow;
  -moz-transition-property: -moz-transform, margin, border-right-color, box-shadow;
  transition-property: transform, margin, border-right-color, box-shadow; */
  -webkit-transition-duration: 600ms;
  -moz-transition-duration: 600ms;
  transition-duration: 600ms;
`;

const BurgerIconDiv = styled.div<IconState>`
  /* border: 1px solid white; */
  display: block;
  position: relative;
  width: 100%;
  -webkit-transition: margin 300ms;
  -moz-transition: margin 300ms;
  transition: margin 300ms ease;
  cursor: pointer;
  ${Bar}:nth-of-type(2) {
    opacity: ${props => (props.showNav ? "0" : "1")};
    -webkit-transition: opacity 150ms;
    -moz-transition: opacity 150ms;
    transition: opacity 150ms;
  }
  ${Bar}:nth-of-type(1) {
    -webkit-transform: ${props =>
      props.showNav ? "rotate(-135deg) translateY(-17.5px)" : "rotate(-180deg)"};
    -moz-transform: ${props =>
      props.showNav ? "rotate(-135deg) translateY(-17.5px)" : "rotate(-180deg)"};
    -ms-transform: ${props =>
      props.showNav ? "rotate(-135deg) translateY(-17.5px)" : "rotate(-180deg)"};
    -o-transform: ${props =>
      props.showNav ? "rotate(-135deg) translateY(-17.5px)" : "rotate(-180deg)"};
    transform: ${props =>
      props.showNav ? "rotate(-135deg) translateY(-17.5px)" : "rotate(-180deg)"};
  }
  ${props =>
    props.showNav &&
    `
    ${Bar}:nth-of-type(3) {
		margin-left: -5px;
		-webkit-transform: rotate(-45deg) translateY(-3px);
		-moz-transform: rotate(-45deg) translateY(-3px);
		-ms-transform: rotate(-45deg) translateY(-3px);
		-o-transform: rotate(-45deg) translateY(-3px);
    transform: rotate(-45deg) translateY(-3px);
    }
  `};
`;

export default BurgerIcon;
