import styled, { css } from "styled-components";
import theme from "../../util/theme";
import { laptopSize } from "../../util/variables";

interface Props {
  fontSize?: number;
  mobileFontSize?: number;
  fontWeight?: number | string;
  textAlign?: string;
  color?: string;
  textTransform?: string;
  noMargin?: boolean;
}

export const AppText = styled.p<Props>`
  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  font-family: ${theme.SECONDARY_FONT}, Arial, sans-serif;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: ${props => (props.fontSize ? props.fontSize : 20)}px;
  line-height: ${props => (props.fontSize ? props.fontSize + 8 : 28)}px;
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};

  /* color: ${props => (props.color ? props.color : theme.BLACK_COLOR)}; */
  /* text-align: ${props => (props.textAlign ? props.textAlign : "left")}; */
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  text-transform: ${props => (props.textTransform ? props.textTransform : "none")};
  @media ${laptopSize} {
    font-size: ${props => (props.mobileFontSize ? props.mobileFontSize : 18)}px;
    line-height: ${props => (props.mobileFontSize ? props.mobileFontSize + 8 : 26)}px;
  }
`;
