import styled from "styled-components";
import { laptopSize, mobileSize } from "../../util/variables";

export const Wrapper = styled.div`
  max-width: 1280px;
  padding-left: 60px;
  padding-right: 60px;
  margin-right: auto;
  margin-left: auto;
  @media ${laptopSize} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${mobileSize} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;
