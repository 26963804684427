import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import theme from '../../util/theme';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('smooth-scroll')('a[href*="#"]');
}

interface Props {
  children?: React.ReactNode;
  color?: string;
  fontSize?: string;
  underlineHeight?: string;
  to: string;
}

export const AnimatedLink: React.FC<Props> = ({
  children,
  color = '#000000',
  fontSize = '32px',
  underlineHeight = '5px',
  to,
}) => {
  return (
    <StyledLink to={to} color={color} fontSize={fontSize} underlineHeight={underlineHeight}>
      {children}
    </StyledLink>
  );
};

interface LinkProps {
  color: string;
  fontSize: string;
  underlineHeight: string;
}

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const StyledLink = styled(Link)<LinkProps>`
  margin-top: 43px;
  font-size: ${props => props.fontSize};
  font-family: ${theme.PRIMARY_FONT};
  line-height: 1.6;
  letter-spacing: 0;
  color: ${props => props.color};
  text-align: center;
  margin: 0 auto;
  padding: 0;
  transition: $animate;
  position: relative;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 300;
  ::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    height: ${props => props.underlineHeight};
    width: 100%;
    margin-top: 3px;
    background-color: ${theme.PRIMARY_COLOR};
  }
  :hover {
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;
