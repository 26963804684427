import React from "react";
import { AppStoreBadge, AppStoreBadgeWhite } from "../../content/assets";
import styled from "styled-components";
import { mobileSize } from "../../util/variables";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const DownloadApple = ({ useAlt }: { useAlt?: boolean }) => {
  return (
    <OutboundLink
      href="https://apps.apple.com/us/app/imprint-learn-visually/id1482780647"
      target="_blank"
      rel="noopener noreferrer"
    >
      <AppleBadge
        src={useAlt ? AppStoreBadgeWhite : AppStoreBadge}
        alt="download on the app store"
      />
    </OutboundLink>
  );
};

const AppleBadge = styled.img.attrs({
  width: 168,
  height: 54,
})`
  @media ${mobileSize} {
    width: 160px;
    height: 52px;
  }
`;
