import * as React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  children?: React.ReactNode;
  color?: string;
  fontSize?: string;
  paddingBottom?: string;
  underlineHeight?: string;
}

export const AnimatedSpan: React.FC<Props> = ({
  children,
  color = '#000000',
  fontSize = '2.05rem',
  paddingBottom = '0.1875rem',
  underlineHeight = '0.325rem',
}) => {
  return (
    <StyledSpan color={color} fontSize={fontSize} paddingBottom={paddingBottom} underlineHeight={underlineHeight}>
      {children}
    </StyledSpan>
  );
};

interface SpanProps {
  color: string;
  fontSize: string;
  paddingBottom: string;
  underlineHeight: string;
}

const SpanAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const StyledSpan = styled.span<SpanProps>`
  margin-top: 2.6875rem;
  font-size: ${props => props.fontSize};
  line-height: 1.6;
  letter-spacing: 0;
  color: ${props => props.color};
  text-align: center;
  margin: 0 auto;
  padding: 0;
  transition: $animate;
  position: relative;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  ::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    height: ${props => props.underlineHeight};
    width: 100%;
    margin-top: ${props => props.paddingBottom};
    background-color: #45aeeb;
  }
  :hover {
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${SpanAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${SpanAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;
