import React, { useEffect, useState } from "react";
import theme from "../../util/theme";
import styled from "styled-components";
import { desktopSize, mobileSize } from "../../util/variables";

interface TopBannerProps {
  children: React.ReactNode;
  show?: boolean;
  fixed?: boolean;
  style?: React.CSSProperties;
}

export const TopBanner: React.FC<TopBannerProps> = ({ children, show, fixed = true, style }) => {
  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowBar(true), 500);
  }, []);

  return (
    <>
      <Banner fixed={fixed} show={show ?? showBar} style={style}>
        {children}
      </Banner>
      <BarSpacer fixed={fixed} show={show ?? showBar} />
    </>
  );
};

interface StyledProps {
  show: boolean;
  fixed: boolean;
}

const Banner = styled.div<StyledProps>`
  position: ${props => (props.fixed ? "fixed" : "block")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  transform: translateY(${props => (props.show ? "0%" : "-100%")});
  transition: transform 1s ease-in-out;

  background-color: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  padding: 24px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-family: ${theme.SECONDARY_FONT};
  a {
    margin-left: 4px;
    color: ${theme.WHITE_COLOR};
    border-bottom: 2px solid #fff67a;
    color: #fff67a;
    font-weight: 500;
    cursor: pointer;
  }

  @media ${desktopSize} {
    padding: 12px;
    font-size: 20px;
  }

  @media ${mobileSize} {
    padding: 16px;
    font-size: 20px;
    line-height: 1.25;
  }
`;

const BarSpacer = styled.div<StyledProps>`
  display: ${props => (props.fixed ? "block" : "none")};
  height: ${props => (props.show ? 76 : 0)}px;
  transition: height 1s ease-in-out;
  @media ${desktopSize} {
    height: ${props => (props.show ? 52 : 0)}px;
  }
  @media ${mobileSize} {
    height: ${props => (props.show ? 88 : 0)}px;
  }
`;
