import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../util/theme';
import { AppText } from './AppText';

interface Props {
  children?: React.ReactNode;
  numberText: number;
  arrowRight?: boolean;
}

const MapTooltip: React.FC<Props> = ({ children, numberText = '0', arrowRight = false }) => {
  return (
    <TooltipContainer>
      <ToolTipArrow arrowRight={arrowRight} />
      <ImagesDiv>{children}</ImagesDiv>
      <Number>
        <NumberText>{numberText}</NumberText>
      </Number>
    </TooltipContainer>
  );
};

const ImagesDiv = styled.div`
  max-height: 80px;
  max-width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  left: 10px;
  background: ${theme.WHITE_COLOR};
`;

const TooltipContainer = styled.div`
  min-height: 80px;
  min-width: 110px;
  background: white;
  position: relative;
`;

const Number = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  right: 10px;
  background: ${theme.PRIMARY_COLOR};
`;

const NumberText = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.WHITE_COLOR};
`;

interface IArrowPosition {
  arrowRight?: boolean;
}

const ToolTipArrow = styled.div<IArrowPosition>`
  height: 30px;
  width: 30px;
  position: absolute;
  background: white;
  margin-left: auto;
  margin-right: auto;
  left: ${props => (props.arrowRight ? '70%' : 0)};
  right: 0;
  bottom: ${props => (props.arrowRight ? '15px' : 0)};
  transform: rotate(45deg);
`;

export { MapTooltip };
