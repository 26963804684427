import * as React from "react";
import styled, { keyframes } from "styled-components";
import theme from "../../util/theme";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface Props {
  children?: React.ReactNode;
  color?: string;
  fontSize?: string;
  underlineHeight?: string;
  paddingBottom?: string;
  to: string;
  target?: string;
}

export const AnimatedLinkAnchor: React.FC<Props> = ({
  children,
  color = "#000000",
  fontSize = "32px",
  underlineHeight = "2px",
  paddingBottom = "5px",
  to,
  target,
}) => {
  return (
    <StyledLinkAnchor
      href={to}
      target={target}
      color={color}
      fontSize={fontSize}
      underlineHeight={underlineHeight}
      paddingBottom={paddingBottom}
    >
      {children}
    </StyledLinkAnchor>
  );
};

interface LinkProps {
  color: string;
  fontSize: string;
  underlineHeight: string;
  paddingBottom: string;
}

const animatedFr = (underlineHeight: string) => keyframes`
  0% {
    background-size: 0% ${underlineHeight};
  }
  100% {
    background-size: 100% ${underlineHeight};
  };
`;

const StyledLinkAnchor = styled(OutboundLink)<LinkProps>`
  font-family: ${theme.PRIMARY_FONT};
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin-top: 43px;
  font-size: ${props => props.fontSize};
  line-height: 1.6;
  letter-spacing: 0;
  color: ${props => props.color};
  text-align: center;
  margin: 0 auto;
  padding: 0;
  transition: ${props => animatedFr(props.underlineHeight)};
  position: relative;
  cursor: pointer;
  padding-bottom: ${props => props.paddingBottom};
  text-decoration: none;
  background-image: linear-gradient(${theme.PRIMARY_COLOR}, ${theme.PRIMARY_COLOR});
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% ${props => props.underlineHeight};
  transition: background-size 400ms;

  :hover {
    text-decoration: none;
    background-size: 100% ${props => props.underlineHeight};
    transition: background-size 400ms;
    animation: ${props => animatedFr(props.underlineHeight)} 400ms ease-in;
  }
  :not(:hover) {
    background-size: 100% ${props => props.underlineHeight};
  }
`;
