import React from "react";
import styled, { css } from "styled-components";
import imprintLogo from "../../content/img/icon/imprint-logotype.png";

interface LogoProps {
  size?: "x-small" | "small" | "medium" | "large" | "x-large";
  width?: number;
  height?: number;
  alt?: string;
  white?: boolean;
  style?: React.CSSProperties;
}

export const AppLogo: React.FC<LogoProps> = ({ size, width, height, alt, white, ...rest }) => {
  const getWidth = () => {
    switch (size) {
      case "x-small":
        return "76px";
      case "small":
        return "96px";
      case "medium":
        return "112px";
      case "large":
        return "127px";
      case "x-large":
        return "150px";
      default:
        return `${width ?? 127}px`;
    }
  };

  return (
    <Img
      src={imprintLogo}
      alt={alt ?? "Imprint logo"}
      // height={getHeight()}
      width={getWidth()}
      white={white ?? false}
      {...rest}
    />
  );
};

const Img = styled.img<{ white: boolean }>`
  ${props =>
    props.white &&
    css`
      filter: invert(1);
    `}
`;
